<template>
  <b-table-simple class="table-bordered table-hover tbody-hover col-md-12">
    <b-thead>
      <b-tr>
        <b-th class="text-center">Ngày</b-th>
        <b-th class="text-center">ID</b-th>
        <b-th class="text-center">Loại phiếu</b-th>
        <b-th class="text-center">Sản phẩm</b-th>
        <b-th class="text-center">Giá</b-th>
        <b-th class="text-center">SL</b-th>
        <b-th class="text-center">Tổng tiền</b-th>
        <b-th class="text-center">Ghi chú</b-th>
      </b-tr>
    </b-thead>
    <b-tbody v-for="bill in mainList" :key="bill.id">
      <b-tr v-for="item in bill.listDetail" :key="item.id">
        <b-td
          :rowspan="bill.listDetail.length + 1"
          class="text-left tdCenter"
          v-show="isShowRow(bill.listDetail, item)"
          style="width: 5%"
        >
          {{ bill.createdAt }}
        </b-td>
        <b-td
          :rowspan="bill.listDetail.length + 1"
          class="text-left tdCenter"
          v-show="isShowRow(bill.listDetail, item)"
          style="width: 5%"
        >
          {{ bill.storeName }}
          <br />

          <a
            class="font-weight-bold"
            v-bind:href="getLinkDoc(bill.id, bill.type)"
            target="_self"
          >
            {{ bill.code }}
          </a>
        </b-td>
        <b-td
          :rowspan="bill.listDetail.length + 1"
          class="text-left tdCenter"
          v-show="isShowRow(bill.listDetail, item)"
          style="width: 10%"
        >
          {{ getType(bill.type) }}
        </b-td>
        <b-td class="text-left" style="width: 25%">
          {{ item.productName }}
          <p class="text-imei-no" v-if="item.productImei.trim() !== ''">
            IMEI: {{ item.productImei }}
          </p>
        </b-td>
        <b-td class="text-right" style="width: 10%">
          {{ convertPrice(item.unitPrice) }}
        </b-td>
        <b-td class="text-right" style="width: 5%">{{ item.quantity }}</b-td>
        <b-td
          :rowspan="bill.listDetail.length + 1"
          class="text-right tdCenter"
          v-show="isShowRow(bill.listDetail, item)"
          style="width: 10%"
        >
          <span>
            {{ convertPrice(bill.totalAmount) }}
          </span>
        </b-td>
        <b-td
          :rowspan="bill.listDetail.length + 1"
          class="text-left tdCenter"
          v-show="isShowRow(bill.listDetail, item)"
          style="width: 10%"
        >
          {{ bill.description }}
        </b-td>
      </b-tr>
    </b-tbody>
  </b-table-simple>
</template>

<style>
.break-line {
  white-space: break-spaces;
}

.text-imei-no {
  font-size: 12px;
  font-style: italic;
  color: dodgerblue;
}
</style>

<script>
import ApiService from '@/core/services/api.service';
import moment from 'moment';
import { STOCK_SLIP_TYPE } from './../../utils/enum';
import _ from 'lodash';

export default {
  props: ['purchaseOrderId'],
  components: {},
  data() {
    return {
      STOCK_SLIP_TYPE,
      mainList: [],
      onLoadingList: false,
    };
  },
  methods: {
    fetchMainData: async function () {
      // Init request header.
      this.onLoadingList = true;
      ApiService.get(`stock-slips/by-purchase/${this.purchaseOrderId}`)
        .then((response) => {
          const respData = response.data.data;
          this.mainList = respData;
          this.onLoadingList = false;
        })
        .catch(() => {
          this.onLoadingList = false;
        });
    },
    formatDate: function (dateVal) {
      const date = moment(dateVal);
      return date.format('DD/MM/yyyy');
    },
    convertPrice: function (number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    isShowRow: function (listData, item) {
      return _.findIndex(listData, ['id', item.id]) === 0;
    },
    getLinkDoc(id, type) {
      if (type === STOCK_SLIP_TYPE.IMPORT_PROVIDER) {
        return `#/stocks/update-stock?id=${id}`;
      } else if (type === STOCK_SLIP_TYPE.EXPORT_PROVIDER) {
        return `#/export-stocks/update-export-stock?id=${id}`;
      }
    },
    getType: function (type) {
      switch (type) {
        case STOCK_SLIP_TYPE.IMPORT_PROVIDER:
          return 'Nhập kho';
        case STOCK_SLIP_TYPE.EXPORT_PROVIDER:
          return 'Xuất trả';
        default:
          return 'Không rõ';
      }
    },
  },
  mounted() {
    this.fetchMainData();
  },
};
</script>
