<template>
  <div>
    <b-modal
      ref="import-product-modal"
      hide-footer
      title="Thêm sản phẩm từ Excel"
      id="modal-prevent-closing"
      size="xl"
    >
      <b-row>
        <b-col>
          <div class="form-group">
            <span>
              <b>Bước 1:</b> Vui lòng chuẩn bị dữ liệu mẫu từ file excel đúng
              format, hoặc có thể tải ở
              <a :href="urlExcel">đây</a>
            </span>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <span> <b>Bước 2:</b> Tiến hành Import </span>
          <div style="padding-top: 15px">
            <b-form-file
              placeholder="Hãy chọn một tập tin"
              @change="selectFile"
              v-model="file"
              ref="file-input"
              class="mb-2"
            ></b-form-file>
          </div>
        </b-col>
      </b-row>
      <b-container class="mt-4 pl-0 pr-0" v-show="importItems.length > 0">
        <h5>
          Danh sách sản phẩm từ file excel( Tổng:
          {{ importItems.length }} sản phẩm)
        </h5>
        <b-table
          id="my-table"
          class="myTable"
          responsive
          bordered
          hover
          :fields="fieldImports"
          :items="importItems"
          :per-page="10"
          :current-page="currentPage"
        >
          <template v-slot:cell(productName)="row">
            <span v-text="row.item.barCode"></span>
            <br />
            <span
              v-text="row.item.productName"
              style="white-space: normal; color: #3699ff"
            ></span>
          </template>
        </b-table>
        <b-pagination
          v-show="importItems.length > 10"
          v-model="currentPage"
          :total-rows="importItems.length"
          :per-page="10"
          aria-controls="my-table"
        ></b-pagination>
      </b-container>
      <b-button
        style="fontweight: 600; width: 70px"
        variant="primary"
        size="sm"
        @click="validData"
        >Lưu</b-button
      >
      <b-button
        style="margin-left: 10px; font-weight: 600; width: 70px"
        variant="secondary"
        size="sm"
        @click="hideModalImportProduct"
        >Hủy</b-button
      >
    </b-modal>
  </div>
</template>

<style>
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}
.table.b-table.table-sm > thead > tr > [aria-sort]:not(.b-table-sort-icon-left),
.table.b-table.table-sm
  > tfoot
  > tr
  > [aria-sort]:not(.b-table-sort-icon-left) {
  background-position: right calc(1.5rem / 2) center;
  padding-right: calc(0.3rem + 0.65em);
}
</style>

<script>
import {
  makeToastSuccess,
  makeToastFaile,
  convertPrice,
} from '../../../utils/common';
import { URL_IMPORT_PRODUCT_PO_SHARE } from './../../../utils/constants';
import xlsx from 'xlsx';
import ApiService from '@/core/services/api.service';
import { v4 } from 'uuid';
import { get } from 'lodash';

export default {
  props: ['storeId'],
  data() {
    return {
      importItems: [],
      file: null,
      excellist: [],
      currentPage: 1,
      urlExcel: URL_IMPORT_PRODUCT_PO_SHARE,
      fieldImports: [],
      listResult: [],
    };
  },
  created() {},
  methods: {
    selectFile(event) {
      this.fieldImports = [
        {
          key: 'productName',
          label: 'Tên sản phẩm',
          stickyColumn: true,
        },
        {
          key: 'unitPrice',
          label: 'Đơn giá',
          tdClass: 'text-right',
          formatter: (value) => {
            return convertPrice(value);
          },
        },
        {
          key: 'salePrice',
          label: 'Đơn giá bán',
          tdClass: 'text-right',
          formatter: (value) => {
            return convertPrice(value);
          },
        },
      ];
      this.importItems = [];
      const files = event.target.files[0];
      this.file = files;
      if (!files) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files.name.toLowerCase())) {
        this.file = null;
        this.clearFiles();
        return makeToastFaile(
          'Định dạng của file tải lên không chính xác. Vui lòng tải lên file có đuôi .xls hoặc .xlsx'
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const XLSX = xlsx;
          const workbook = XLSX.read(data, {
            type: 'binary',
          });
          const wsname = workbook.SheetNames[0];
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            blankRows: false,
            defval: "",
          });
          if (!ws || !ws.length) {
            return makeToastFaile('Vui lòng nhập dữ liệu import!');
          }
          const excellist = [];
          // Edit data
          for (var i = 0; i < ws.length; i++) {
            excellist.push(ws[i]);
          }
          this.excellist = excellist;
          const masterFields = [
            'Đơn giá',
            'Đơn giá bán',
            'Tên sản phẩm',
            'Barcode',
          ];
          const excellHeaders = Object.keys(this.excellist[0]);
          const storeKeys = excellHeaders
            .filter((excellHeader) => !masterFields.includes(excellHeader))
            .map((storeKey) => storeKey.trim());
          storeKeys.forEach((storeKey) => {
            this.fieldImports.push({
              key: storeKey,
              label: storeKey,
              tdClass: 'text-right',
              formatter: (value) => {
                return convertPrice(value);
              },
            });
          });
          this.excellist.map((element) => {
            const unitPrice = element['Đơn giá'] ? element['Đơn giá'] : 0;
            const salePrice = element['Đơn giá bán']
              ? element['Đơn giá bán']
              : 0;

            const data = {
              productName: element['Tên sản phẩm']
                ? element['Tên sản phẩm']
                : '',
              barCode: element['Barcode'] ? element['Barcode'] : '',
              unitPrice: unitPrice,
              salePrice: salePrice,
            };
            storeKeys.forEach((storeKey) => {
              data[storeKey] = element[storeKey] ? element[storeKey] : 0;
            });
            this.importItems.push(data);
          });
        } catch (e) {
          return alert('Read failure!');
        }
      };
      fileReader.readAsBinaryString(files);
    },
    clearFiles() {
      this.$refs['file-input'].reset();
    },
    showModal: function () {
      this.importItems = [];
      this.excellist = [];
      this.$refs['import-product-modal'].show();
    },
    hideModalImportProduct() {
      this.$refs['import-product-modal'].hide();
    },
    validData: async function () {
      this.listResult = [];

      await ApiService.post(
        'purchase-order/transfer-data-import',
        this.importItems
      )
        .then((response) => {
          const { status, message } = response.data;
          if (status === 1) {
            makeToastSuccess(message);
            const { transformedProducts, inventories } = response.data.data;
            transformedProducts.forEach((element) => {
              const item = {
                id: v4(),
                productName: element.productName,
                productCode: element.productCode,
                barCode: element.barCode,
                productId: element.productId,
                unitPrice: element.unitPrice,
                quantity: element.quantity,
                quantityApprove: element.quantityApprove,
                salePowerWeek: element.salePowerWeek,
                salePowerMonth: element.salePowerMonth,
                prevUnitPrice: element.prevUnitPrice,
                inStockQuantity: element.inStockQuantity,
                discountAmount: element.discountAmount,
                totalAmount: element.totalAmount,
                productType: element.productType,
                salePrice: element.salePrice,
                isNew: true,
              };
              this.listResult.unshift(item);
            });
            this.hideModalImportProduct();
            const emitData = {
              products: this.listResult,
              inventories: inventories,
            };
            this.$emit('validData', emitData);
          } else {
            makeToastFaile(message);
          }
        })
        .catch((err) => {
          makeToastFaile(get(err, 'response').data.message);
        });
    },
  },
};
</script>
